import React from "react";

const PricingBadge = ({ text }) => {
    return (
        <div className="jlt-relative -jlt-mt-2 jlt-flex jlt-items-center jlt-h-7 jlt-text-sm jlt-text-white jlt-font-semibold jlt-p-[6px_22px_6px_6px] jlt-bg-[#ad049a] jlt-select-none before:jlt-absolute before:jlt-content-[''] before:jlt-top-0 before:-jlt-left-4 before:jlt-w-0 before:jlt-h-0 before:jlt-border-t-[14px] before:jlt-border-b-[14px] before:jlt-border-r-[16px] before:jlt-border-t-transparent before:jlt-border-b-transparent before:jlt-border-r-[#ad049a] after:jlt-absolute after:jlt-content-[''] after:jlt-top-0 after:jlt-right-[0] after:jlt-w-0 after:jlt-h-0 after:jlt-border-t-[14px] after:jlt-border-b-[14px] after:jlt-border-r-[16px] after:jlt-border-t-transparent after:jlt-border-b-transparent after:jlt-border-r-[#150f1a]">
            {text}
        </div>
    );
};

export default PricingBadge;
